<template>
  <base-table-row
    class="tariffs-table-item"
    :columns="columns"
    v-bind="item"
    col-class="tariffs-table-item__col"
  >
    <template #name>
      <router-link
        :to="{ name: 'Firewall', params: { firewallid: item.id } }"
        class="tariffs-table-item__link medium-text"
      >
        <div class="hover">
          {{ item.name }}
        </div>
      </router-link>
      <div class="tariffs-table-item__specs note-text">
        <div class="hover">
          {{ item.description }}
        </div>
      </div>
    </template>
    <template #id>
      <div class="tariffs-table-item__ip">
        {{ item.id }}
      </div>
    </template>
    <template #shared>
      <div class="tariffs-table-item__ip">
        {{ item.shared }}
      </div>
    </template>
    <template #more>
      <v-popover
        v-click-outside="closePopoverOnOutside"
        placement="left"
        :open="isPopoverOpen"
        trigger="manual"
        popover-class="tariff-table-item__popover"
      >
        <plain-button
          icon="more"
          class="tariffs-table-item__more"
          @click="togglePopoverState"
        /><template #popover>
          <firewall-context-menu :tariff="item" class="tariff-table-item__context-menu"
        /></template>
      </v-popover>
    </template>
  </base-table-row>
</template>

<script>
import BaseTableRow from '@/components/Table/BaseTableRow';
import FirewallContextMenu from './FirewallContextMenu';
import popoverFix from '@/mixins/popoverFix';
export default {
  name: 'TariffsTableFirewallRow',
  components: {
    BaseTableRow,
    FirewallContextMenu,
  },
  mixins: [popoverFix],
  props: {
    columns: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "activeUnder": "До {date} г.",
    "mb": "{num} МБ",
    "gb": "{num} ГБ",
    "sure": {
      "confirm": "Подключить",
      "success": "Подсеть добавлена успешно",
      "fail": "Что-то пошло не так",
      "close": "Хорошо"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.hover{
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  background: var(--main-card-bg);
  z-index: 2
  &:hover {
    background: var(--main-card-bg);
    text-overflow: unset;
    overflow: visible;
    display inline
    z-index: 3;
  }
  &:hover::after {
    +breakpoint(ml-and-up) {
      content: '';
      //display: block;
      display: inline;
      size(2.5em, 100%);
      absolute(0px, 100%);
      z-index: 2
      background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
    }
  }
}
.tariffs-table-item {
  font-size: 1rem;
  >>> &__col {
    vertical-align: middle;

    +breakpoint(md-and-down) {
      min-width: min-content;
      max-width: unset!important;
    }
  }
  &__sub{
    margin-top: 0.5rem;
  }
  &__specs {
    flex: 1 1 auto;
    margin-top: 0.12rem;
  }
  &__link {
    position: relative;
    text-decoration: none;
    white-space: nowrap;
    background: var(--main-card-bg);
    color: $primary-color;
  }
}
</style>

<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariff-table-item {
  &__popover {
    text-align: left
    &.tooltip[x-placement^="left"] {
      +breakpoint(ms-and-down) {
        margin-right: 0;
      }
    }
    &.tooltip[x-placement^="right"] {
      +breakpoint(ms-and-down) {
        margin-left: 0;
      }
    }
    .popover-arrow {
      +breakpoint(ms-and-down) {
        display: none;
      }
    }
  }

  &__context-menu {
    +breakpoint(ms-and-down) {
      max-width: 100%;
      width: calc(100vw - 6rem);
    }
  }
}
</style>
